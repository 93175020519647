<template>
    <v-container>
        <v-container class="px-0 pb-6">
            <v-row>
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        Request Statistics
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <stats-sheet
                        label="Total Recurring"
                        :loading="isLoading"
                        :value="recurringQueue.total"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <stats-sheet
                        label="Recurring Overdue"
                        :loading="isLoading"
                        :value="recurringQueue.overdue"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <router-link
                        to="/admin/blog-posts/approval"
                        class="text-decoration-none"
                    >
                        <stats-sheet
                            label="MSN DIY For Review"
                            :loading="isLoading"
                            :value="msnQueueCount"
                        />
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="px-0 pb-0">
            <v-row class="justify-space-between">
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        Editor's Panel
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="auto"
                    class="d-flex flex-wrap dense-fields"
                >
                    <v-switch
                        v-model="showFlagged"
                        label="Show Only Flagged"
                        :disabled="isLoading"
                        dense
                        inset
                        class="mt-0 ml-12"
                    />
                    <v-switch
                        v-model="showLimitedDistribution"
                        label="Selective Distribution"
                        :disabled="isLoading"
                        dense
                        inset
                        class="mt-0 ml-12"
                    />
                    <author-selector
                        :authors="authors"
                        :loading="isLoading"
                        :query="{ showFlagged: showFlagged ? 1 : void 0 }"
                        class="ml-12 authors--select"
                        can-be-empty
                    />
                </v-col>
            </v-row>
        </v-container>

        <assignments-list
            :assignments="assignments"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0 pt-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :updating="isUpdating"
            size="10"
            class="main-background px-0"
        />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { ListPaged } from '@/mixins';

import { AuthorSelector } from '@/components/AuthorSelector';
import { Paging } from '@/components/Paging';
import { StatsSheet } from '@/components/StatsSheet';

import { AssignmentsList } from '@/components/Authoring/AssignmentsList';

import type { AuthorUser } from '@/types/User';
import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        StatsSheet,
        AuthorSelector,
        AssignmentsList,
        Paging
    },
    computed: {
        ...mapState('authors', ['authors']),
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AuthorRequestsEditorial extends mixins(ListPaged) {
    isAuthor!: boolean;
    isEditor!: boolean;
    authors!: AuthorUser[];

    assignments: Assignment[] = [];

    recurringQueue = {
        total: 0,
        overdue: 0
    };
    msnQueueCount = 0;

    endpoint = '/author_requests_pool/editorial';

    get queryParams() {
        return {
            author_id: 0,
            limit: 10,
            page: 1,
            sort: 'priority',
            asc: 1,
            showFlagged: 0,
            showLimitedDistribution: 0
        };
    }

    get showFlagged() {
        return this.$route.query.showFlagged === '1';
    }

    set showFlagged(showFlagged: boolean) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0,
                showFlagged: showFlagged ? '1' : void 0
            }
        });
    }

    get showLimitedDistribution() {
        return this.$route.query.showLimitedDistribution === '1';
    }

    set showLimitedDistribution(showLimitedDistribution: boolean) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                page: void 0,
                showLimitedDistribution: showLimitedDistribution ? '1' : void 0
            }
        });
    }

    onData(data: {
        assignments: Assignment[];
        length: number;
        recurringQueue: {
            total: number;
            overdue: number;
        };
        msnQueueCount: number;
        authors: Record<number, string> | null;
    }) {
        if (data) {
            this.assignments = data.assignments || [];
            this.total = data.length;

            this.recurringQueue = data.recurringQueue;
            this.msnQueueCount = data.msnQueueCount;

            this.$store.dispatch('authors/set', data.authors);
        }
    }
}
</script>

<style lang="scss" scoped>
.authors--select {
    max-width: min-content !important;
    padding: 0;
    margin: 0;
    min-width: 200px;
    position: relative;
    top: -5px;
}
</style>
